body {
    margin: 0;
    padding: 0;
    font-family: 'kozuka-gothic-pro' !important;
    font-style: normal;
    font-weight: 200;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #FAFAFA;
    color: #0A182C;
  }
  .player .vp-sidedock{
    display: none !important;
}
  @media only screen and (max-width: 638px) {
    body{
        background-color: #DCDCDC;
    }
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  
  .formik-error{
    height: 10px;
    color: red;
    font-size: 14px;
  }

  .red-text{
    color: red;
  }

  .error{
    color: red;
  }

  .flex-between-center{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .flex-between-center::before,
  .flex-between-center::after{
    content: none
  }

  .flex-center{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  

  .input-radius{
    border-radius: 32px;
    padding: 4px 12px;
    outline: none;
    border: 1px solid #DEDEDE;
    background-color: white;
  }

  .center-placeholder::placeholder{
    color: #9EABB9;
  }
  .center-placeholder:placeholder-shown{
    text-align: center;
  }

  a{
    text-decoration: none !important;
  }

  input:focus, textarea:focus, select:focus{
    outline: none;
  }

  .unset-all{
    all: unset !important;
  }

  pre{
    font-size: 100%;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
  }

  p{
    white-space: pre-wrap;
  }

  .pre-wrap-label{
    white-space: pre-wrap;
    font-size: 14px;
  }

  .border-radius-none{
    border-radius: 0px !important;
  }

  .border-none{
    border: 0px !important;
  }

  .item-opacity-disabled{
    opacity: 0.5;
  }

  .bold{
    font-weight: bold !important;
  }

  .custom-menu-item{
    padding: 0;
  }

  .custom-menu-item:hover{
    background-color: initial;
  }

  .cursor-pointer{
    cursor: pointer;
  }