@import "../../../../styles/scss/global";
@import "../../../../styles/scss/layout";

.login{
    @include flex-center;

    &-title{
        margin-bottom: 40px;
    }

    &-container{
        @extend .login;
        @include no-margin-horizontal;
        max-width: 100% !important;
        height: 100vh;
    }

    &-card{
        @extend .login;
        width: 450px;
        min-height: 400px;
        -webkit-box-shadow: 0px 0px 16px -10px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 16px -10px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 16px -10px rgba(0,0,0,0.75);
    }

    &-form{
        @extend .login;
        flex-direction: column;
        width: 100%;
        padding-left: 40px;
        padding-right: 40px;
    }

    &-input{
        width: 100%;
    }

    &-button{
        @include padding-vertical(8px);
        width: 200px;
        margin-top: 30px;
        background-color: $admin_primary !important;
        border: 0 !important;
    }

    &-error{
        color: red;
    }
}