/* The container */
.label-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  margin-top: 15px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser radio button */
.label-container input {
  opacity: 0;
  cursor: pointer;
}

/* Custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid #b1bcc7;
}

/* On mouse-over, add a grey background color */
.label-container:hover input ~ .checkmark {
  background-color: #ccc !important;
}

/* When the radio button is checked, add a white background */
.label-container input:checked ~ .checkmark {
  background-color: #ffffff !important;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.label-container input:checked ~ .checkmark:after {
  display: block !important;
}

/* indicator (dot/circle) */
.label-container .checkmark:after {
  top: 4px;
  left: 4px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #606770;
}

@media (max-width: 768px) {
  .label-container {
    display: block;
    position: relative;
    padding-left: 34px;
    margin-bottom: 20px;
    margin-top: 15px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}