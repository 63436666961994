@import '../../../../styles/scss/layout';

.feed-card-header{
    padding: 10px 20px;
    background-color: #F4F4F4;
    font-size: 16px;
    font-weight: 500;
}

.feed-card-container{
    @include flex-row-center;
    padding: 24px 20px !important;
    border: none !important; 
}

.feed-card-create{
    flex: 1;
}
