@import '../../../../styles/scss/layout';

.friend-privacy-mobile{
    flex: 1;
    cursor: pointer !important;
    margin-left: 15px;
    align-items: flex-start;
    width: 110px;
    border-radius: 20px
}

.public-privacy-mobile{
    cursor: pointer !important;
    margin-left:  12px;
    align-items: flex-start;
    width: 120px
}
.privacy-main-container{
    align-self: center;
    margin-bottom: 20px;
}
.privacy{
    flex-direction: row;
    align-self: center;
    display:flex;
}
.friend-privacy{
    width: 120px;
    border-radius: 20px
}
.public-privacy{
    width: 190px
}
.select-text-pc{
    font-size: 16px;
    margin-top: 8px;
    margin-left: 10px;
    @include kozuka-gothic-font;
}
.select-text-mobile{
    font-size: 14px;
    margin-top: 7px;
    margin-left: 7px;
    @include kozuka-gothic-font;
}

.react-select__control{
    box-shadow: none!important;
    border-color: #DCDCDC!important;
    border-radius: 12px!important;
}

.react-select__indicator-separator{
    display: none;
}

.react-select__indicator svg{
    color: hsl(0,0%,40%);
}
.react-select__single-value{
    font-size: 14px;
}

@media screen and (max-width: 638px) {
    .privacy-main-container{
        align-self: center;
        margin-bottom: 0px;
        border: none!important;
        border-radius: 0!important;
    }
    .privacy{
        align-self: flex-start;
        padding-left : 0!important
    }
    .react-select__single-value{
        font-size: 12px;
    }
    .react-select__menu{
        font-size: 11px;
    }
}

