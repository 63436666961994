/* Calendar Icon custom CSS */

/* Calendar footer */
.sc-dlnjPT, .cgMNNr{
    display: none !important;
}

/* Calendar container */
.sc-bdnylx{
    border: 1px solid #0A182C;
    width: 60px !important;
    height: 60px !important;
}

/* Calendar Date Text */
.sc-hKFyIo{
    font-size: 24px !important;
}

/* Calendar modal */
.MuiPickersToolbar-toolbar, .MuiPickersDay-daySelected{
    background-color: #0A182C !important;
}

.calendar-group{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.calendar-plain-filter{
    width: 60px;
    height: 60px;
}


@media only screen and (max-width: 768px) {
    .calendar-plain-filter, .sc-bdnylx{
        width: 50px !important;
        height: 50px !important;
    }
    .sc-hKFyIo{
        font-size: 18px !important;
    }
    
}