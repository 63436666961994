@import '../../../../styles/scss/layout';

//Hide Vimeo Embed Button
.player .vp-sidedock{
    display: none !important;
}

.pad-container{
    padding: 20px;
}

.post-author-name{
    font-size: 16px;
    color: #1d1d1d;
    font-weight: 500;
    margin-left: 8px;
}

.post-body{
    width: 100%;
    padding: 10px 0px;
    margin: 10px 0px;
    border: 0 !important;
}

.post-add-buttons, .post-add-image{
    width: 120px;
    height: 120px;
    margin-right: 12px;
    margin-bottom: 12px;
}

.post-add-image{
    border-radius: 10px;
    object-fit: cover;
    border: 1px solid #cacaca;
}

.post-actions-row{
    padding-left: 16px;
    padding-right: 16px;
    flex-wrap: nowrap !important;
    overflow-x: auto;
    overflow-y: hidden;
}

.vimeo-modal-input{
    max-width: 420px;
    margin-top: -10px;
    margin-bottom: 30px;
    align-self: center;
    border-radius: 10px !important;
    border: 1px solid #B1BCC7 !important;
}
.mobile-privacy-select{
    width: 100%;
    border-top: 1px solid rgba(0,0,0,.1);
    border-bottom: 1px solid rgba(0,0,0,.1);
    padding: 20px 0px !important;
    margin: 20px 0px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}
.pc-privacy-select{
    width: 100%;
    border-top: 1px solid rgba(0,0,0,.1);
    border-bottom: 1px solid rgba(0,0,0,.1);
    padding: 20px !important;
    margin: 20px 0px !important;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.mobile-privacy-input{
    flex: 1;
    cursor: pointer !important;
    margin-left: 20px;
}

.pc-privacy-input{
    min-width: 250px;
    cursor: pointer !important;
    margin-left: 12px;
}

.pc-save-button{
    @include pc-primary-button;
    position: relative;
    display: flex;
    justify-content: center !important;
    align-items: center;
    width: 45%;
}

.mobile-save-button{
    @include pc-primary-button;
    position: relative;
    display: flex;
    justify-content: center !important;
    align-items: center;
    width: 100%;
    border-radius: 14px !important;
}

.save-icon{
    @include primary-button-icon;
}

.post-attachment{
    width: 100% !important;
    min-height: 460px;
    margin-bottom: 4px;
    object-fit: cover;
    position: relative;
}

.post-attachment-video{
    width: 100% !important;
    min-height: 460px;
    position: relative;
    margin-bottom: 4px;
}

.post-attachment-video iframe{
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.video-content{
    // position: absolute;
    // top: 0;
    // left: 0;
    // width: 100%;
    // height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    height: 460px;
}

.attachment-menu{
    position: absolute !important;
    top: 0;
    margin-top: 5px;
    border-radius: 50%;
    padding: 6px;
    right: 14px;
}
.attachment-dropdown{
    width: 200px;
    color: #fff !important;
    background-color: rgba(0,0,0,0.75) !important;
}

.livestream-area{
    border-top: 1px solid #DCDCDC;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 40px;
    font-size: 14px;
}

.livestream-date{
    margin-left: 6px;
    margin-right: 6px;
    font-size: 12px;
}
.livestream-date-mobile{
    margin-left: 3.5px;
    margin-right: 3.5px;
    font-size: 10.5px;
}

.livestream-label{
    font-weight: normal !important;
    padding-top: 16px !important;
    padding-bottom: 8px !important;
}

.warning-text{
    color: red;
    font-size: 14px;
}

.post-profile-icon{
    width: 36px; 
    height: 36px;
    object-fit: cover
}

.custom-toggle-icon{
  width: 24px;
  height: 24px;
}

@media (max-width: 768px) {
    .post-add-buttons, .post-add-image{
        width: 85px;
        height: 85px;
        margin-bottom: 6px;
    }
}

//Embedded Video Container
@media (max-width: 1199px) {
    .post-attachment-video{
        min-height: 390px;
    }
}

@media (max-width: 991px) {
    .post-attachment-video{
        min-height: 298px;
    }
}

@media (max-width: 638px) {
    .post-attachment-video{
        min-height: 56vw;
    }
}