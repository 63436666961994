.search-title{
    padding: .75rem 1.25rem !important;
    background-color: #F4F4F4 !important;
    border-bottom: 1px solid #DCDCDC !important;
    font-size: 16px !important;
    margin-bottom: 0px !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}
.search-mobile{
    position: fixed;
    width: 100%;
    z-index: 10;
    border-radius: 0px;
}
.search-bar-web{
    position: sticky;
    background-color: #FAFAFA;
    top: 45px;
    z-index: 99;
    padding-top: 50px;
    margin-top: -55px;
    border-radius: 10px;
    margin-bottom: 28px;
}
.search-bar-mobile{
    position: fixed;
    z-index: 99;
    border-radius: 0px;
}
.search-web{
    border-radius: 10px;
    border: 1px solid #DCDCDC;
}
.search-container-mobile{
    background-color: #F5F5F5;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 40px;
    padding-right: 40px;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 999;
    margin-top: -3px;
}
.tab-pane{
    padding-top: 16px;
    margin-left: 20px;
    margin-right: 20px;
}

/**Landscape View*/
@media only screen and (max-width: 768px) , (orientation: landscape) {
    .search-container-mobile{
        padding-top: 14px;
        padding-bottom: 14px;
    }
    .search-mobile{
        position: absolute;
        background-color: #F5F5F5;
        z-index: 99;
        border-radius: 0px;
    }
    .result-tabs{
        border-radius: 0px;
    }
    .tab-content-mobile .tab-content{
        padding-top: 0px;
    }
}

@media (max-width: 320px) {
    .tab-pane{
        padding-top: 65px;
    }
}

@media only screen and (min-width: 768px), (orientation: portrait)  {
    .search-input-area{
        border: 1px solid #DCDCDC;
    }
    .search-mobile{
        position: fixed;
        background-color: #F5F5F5;
        z-index: 99;
        border-radius: 0px;
    }
}

.search-container-web{
    background-color: #FFF;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 100px;
    padding-right: 100px;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 999;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.search-input-area{
    width: 100%;
    flex-direction: row;
    display: flex;
    position: relative;
    border-radius: 20px;
    background-color: #ffffff;
    border-width: 1px;
    border-style: solid;
    align-items: center; 
}
.search-icon{
    height: 32px;
    width: 38px;
    right: 5px;
    padding-left: 8px;
    padding-right: 8px;
    position: absolute;
    margin-top: -18px;
    z-index: 12;
}
.half-opacity{
    opacity: 0.5;
}
.result-tabs{
    border-bottom: 0px !important;
    justify-content: center;
    width: 100%;
}
.result-tabs .nav-link{
    color: #0A182C !important;
    border-radius: 0px !important;
    width: 125px;
    text-align: center;
}

.result-tabs .nav-link.active{
    background-color: transparent !important;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    border-bottom: 2px solid #0A182C !important;
}
.tab-content-mobile{
    background-color: white;
    min-height: 89vh;
    padding-bottom: 20px;
}

.tab-content-web{
    background-color: none;
    min-height: 89vh;
    padding-bottom: 20px;
}