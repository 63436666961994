@import "../../../../styles/scss/global";
@import "../../../../styles/scss/layout";

.maintenance{
    @include flex-center;

    &-dropdown{
        color: $admin_primary;
    }

    &-status{
        display: 'inline';
        color: 'green';
        font-weight: 'bold';
    }
}