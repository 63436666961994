.feed-container-item{
    margin-bottom: 20px;
}
.post-feed-item{
    margin-bottom: 20px !important;
}
.feed-container-view{
    margin-bottom: 100px;
}

.pc-border{
    border: 1px solid #DCDCDC !important;
}

@media only screen and (max-width: 638px) {
    .feed-container-item{
        margin-bottom: 4px;
    }
    .post-feed-item{
        margin-bottom: 4px !important;
    }
    .feed-container-view{
        margin-bottom: 50px;
    }
    .pc-border{
        border: 0px !important;
    }
  }