@import '../../../styles/scss/global';

.pagination .page-item .page-link{
    background: none !important;
    color: $admin_primary;
    border: 0px;
    // cursor: pointer;
}

.pagination .page-item.active .page-link{
    color: $admin_dark;
    font-size: 17px;
    font-weight: bold;
    z-index: inherit;
}

.pagination-caret{
    font-weight: bold !important;
    color: $admin_dark !important;
    margin-top: -2px;
    text-decoration: none !important;
    cursor: pointer !important;
}

.pagination-caret:hover{
    color: $admin_primary !important;
}