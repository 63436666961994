@import '../../../../styles/scss/layout';
@import '../../../../styles/scss/global';

.admin-container{
    @include flex-row;
    width: 100%;
    background-color: #F6F6F6;
}

.admin-content{
    @include flex-column;
    width: 100%;
    margin-left: $admin_sidenav_width;
}

.admin-body{
    padding: 30px;
}

.admin-title{
    font-size: 28px;
    color: $admin_grey;
    font-weight: bold;
}

.admin-subtitle{
    font-size: 20px;
    color: $admin_grey;
    font-weight: bold;
    border-bottom: 2px solid #E8EBEE;
    padding-bottom: 16px;
}

.admin-subtitle-colored{
    font-size: 20px;
    color: $admin_primary;
    font-weight: bold;
}