@import '../../../../styles/scss/layout';
@import '../../../../styles/scss/global';

.user-main{
    &-profile{
        font-size: 14px;
        font-weight: bold;
        text-decoration: none;
        color: $admin_dark;
        display: flex;
        flex-direction: row;
    }
    &-cardnav{
        width: 255px;
        padding-top: 12px !important;
        padding-bottom: 12px !important;
        color: $admin_dark !important;
    }
    &-snsnav{
        margin-top: 8px !important;
        margin-bottom: 8px !important;
    }
    &-sidenav{
        position: fixed;
    }
    &-handleName{
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 170px;
        display: block;
        overflow: hidden;
        margin-top: 8px;
        font-variant: proportional-width;
        font-size: 16px;
        color: #0A182C;
    }
}


@media only screen and (max-width: 1199px) {
    .user-main-cardnav{
        width: 212px;
    }
}

@media only screen and (max-width: 991px) {
    .user-main-cardnav{
        width: auto;
    }
}

@media only screen and (max-width: 768px) {
    // .user-main-container{
    //     flex-direction: column-reverse;
    // }
    // .user-main-sidenav{
    //     position: relative;
    //     width: 100% !important;
    // }
    // .user-main-sidenav-mobile{
    //     position: relative;
    //     width: 100% !important;
    // }
    // .user-main-handleName{
    //     width: 100%;
    // }
    .user-main-container{
        margin: 0;
    }
    .user-module-container{
        padding: 0 !important;
    }
}

/**MOBILE VIEW**/
@media only screen and (max-width: 638px) {
    .user-main-container{
        margin: 0;
    }
    .user-module-container{
        padding: 0 !important;
    }
}