@import '../../../../styles/scss/layout';
@import '../../../../styles/scss/global';

.author-container{
    @include flex-row;
    align-items: center;
    text-decoration: none !important;
    margin-bottom: 14px !important;
}

.author-name{
    font-size: 16px;
    color: #0A182C;
    font-weight: 500;
}

.author-datetime{
    margin-top: -4px !important;
    font-size: 12px;
    color: $user_grey;
}