@import './global';

@mixin flex-center{
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin flex-center-around{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

@mixin flex-center-between{
    display: flex;
    justify-content: space-between !important;
    align-items: center;
}

@mixin flex-row{
    display: flex;
    flex-direction: row;
}

@mixin flex-column{
    display: flex;
    flex-direction: column;
}

@mixin flex-row-center{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@mixin no-margin{
    margin: 0;
}

@mixin no-margin-vertical{
    margin-top: 0;
    margin-bottom: 0;
}

@mixin no-margin-horizontal{
    margin-left: 0;
    margin-right: 0;
}

@mixin padding-vertical($pixels){
    padding-top: $pixels !important;
    padding-bottom: $pixels !important;
}

@mixin padding-horizontal($pixels){
    padding-left: $pixels !important;
    padding-right: $pixels !important;
}

// Confirmation modal css styling
@mixin modal-pc-container() {
    width: 45%;
    min-height: 200px;
    height: auto;
    padding-bottom: 32px;
    padding-left: 20px;
    padding-right: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 10px;
}

@mixin iframe-modal-container() { //task#363 start
    width: 50%;
    height: 100%;
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 0px;
} //task#363 end
  
@mixin modal-overlay() {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
}
  
@mixin modal-pc-button-container() {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

@mixin iframe-modal-pc-button-container { //task#363 start
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}                                         //task#363 end
  
@mixin modal-mobile-button-container() {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // margin-left: 15px;
}
  
@mixin modal-tablet-container() {
    width: 50%;
    min-height: 200px;
    height: auto;
    padding-bottom: 32px;
}
  
@mixin modal-mobile-container() {
    width: 85%;
    min-height: 200px;
    height: auto;
    padding-bottom: 32px;
}

@mixin iframe-modal-tablet-container() { //task#363 start
    width: 70%;
    height: 100%;
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 0px;
}

@mixin iframe-modal-mobile-container() { //task#363 start
    width: 100%;
    min-height: 200px;
    height: 100%;
    padding-bottom: 0px;
}

@mixin iframe-style() {
    width: 97%;
    min-height: 200px;
    height: 97%;
    padding-bottom: 32px;
}                                      //task#363 end

//User form input
@mixin post-rounded-input-pc(){
    border-radius: 5px !important;
    border: 1.25px solid #B1BCC7 !important;
    padding: 6px 12px !important;
}

@mixin post-rounded-input-mobile(){
    border-radius: 14px !important;
    border: 1.25px solid #B1BCC7 !important;
    padding: 6px 12px !important;
}

@mixin pc-primary-button(){
    background-color: $user_primary;
    color: #fff;
    width: 150px;
    height: 36px;
    font-size: 14px;
    border-radius: 5px;
    border: 0 !important;

    &:disabled{
        background-color: $user_grey !important;
    }
}


@mixin primary-button-icon(){
    position: absolute;
    right: 6px;
    margin-top: -2px;
    width: 36px;
}

@mixin chat-rounded-input(){
    border-radius: 15px !important;
    border: 1px solid #98A6B5 !important;
    padding: 6px 12px !important;
}

@mixin kozuka-gothic-font() {
    font-family: kozuka-gothic-pro, sans-serif;
    font-style: normal;
    font-weight: 200;
}
