@import '../../../../styles/scss/layout';

.mypage-info-contaier{
    flex: 1;
}

.mypage-cover-photo-container{
    height: 330px;
    padding: 0 !important;
}

.mypage-profile-photo-container{
    height: 150px;
    position: absolute;
    top: 250px;
    left: 50%;
    margin-left: -80px;
}

.profile-cover-image{
    width: 100%;
    object-fit: cover;
    height: 330px;
    max-height: 330px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #0A182C;
}
._loading_overlay_overlay{
    z-index: 2;
}

.profile-image{
    width: 157px;
    height: 157px;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid white;
}

.cover-camera{
    width: 72px;
    height: 30px;
    background-color: rgba(0, 0, 0, .7);
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    color: white;
    font-size: 12px;
    border-radius: 3px;
}
.cover-edit-upload{
    position: relative;
    width: 100%;
}

.content{
    font-size: 11px;
    margin: 2px 5px;
    width: 50%;
}

.custom-file-upload{
    display: flex;
    flex-direction: row;
    cursor: pointer;
    margin: 5px 10px;
}

.cover-upload{
    display: none;
}

.profile-camera{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 3px solid white;
    background-color: black;
    position: absolute;
    top: 100px;
    left: 75%;
    display: flex;
}

.header-icon{
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.camera-icon{
    width: 20px;
    height: 20px;
    margin: 7px;
    cursor: pointer;
}

.mypage-profile-menu{
    flex: 1;
    position: absolute;
    right: 20px;
}
.mypage-profile-menu-items{
    width: 185px;
    background-color: rgba(0, 0, 0, .7);
    position: absolute;
    right: 10px;
    display: flex;
    color: white;
    margin-top: 15px;
    border-radius: 2px;
    flex-direction: column;
}
.friendpage-profile-menu-pos {
    top: 534px;
    right: 158px;
}
.mypage-friend-menu-items{
    width: 140px;
    background-color: rgba(0, 0, 0, .7);
    position: absolute;
    display: flex;
    color: white;
    margin-top: 15px;
    font-size: 12px;
    border-radius: 2px;
    flex-direction: column;
}
.friendpage-friend-menu-pos {
    top: 534px;
    left: 197px;
}
.menu-item{
    padding: 5px 10px;
    display: flex;
    flex-direction: row;
}

.menu-item p{
    margin: 0;
    margin-left: 10px;    
    font-size: 14px;
    @include kozuka-gothic-font;
    text-decoration: none;
    color: white
}


.mypage-bio-container{
    display: flex;
    height: auto;  
    text-align: center;
    padding-top: 50px!important;    
    margin-bottom: 10px!important;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
}

.horizontal-border{
    border-top: .5px solid #DCDCDC;
    width: 96%!important;
    margin: 0 auto!important;
}

.mypage-info-container{
    display: flex;
    flex-direction: row;
}

.category-container{
    display: flex;
    margin-right: 20px;
    flex-direction: column;
    @include kozuka-gothic-font;
    font-size: 16px;
}

.category-detail{
    display: flex;
    width: 400px;
}

.friends-card-container{
    display: flex;
    flex-direction: row;
    padding: 0!important;
    padding-top: 10px!important;
}

.icons-card-container{
    display: flex;
    flex-direction: row;
    padding: 0!important;
    padding-top: 10px!important;
    padding-bottom: 10px!important;
}

.icons-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90px;
    text-align: center;
    margin: 0 5px;
}

.icons-inner-container{
    display: flex;
    flex-direction: row;
    margin: 0 auto
}

.friend-image{
    background-color: #98A6B5;
    object-fit: cover;
    width: 250px;
    height: 220px;
    border-radius: 10px;
}

.friend-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
    text-align: center;
    margin: 0 33px;
}

.link-router{
    text-decoration: none!important;
}

.friend-handlename{
    white-space: nowrap;
    font-variant: proportional-width;
    text-overflow: ellipsis;
    width: 150px;
    margin: 0 auto;
    display: block;
    overflow: hidden;
    text-decoration: none;
    color:black;
    padding-top: 10px;
    @include kozuka-gothic-font;
    font-size: 18px;
}

.profile-category{
    font-size: 16px;
    width: 80px;
    text-align: left;
}

.profile-category-detail{
    font-size: 16px;
    text-align: left;
}

.preview-icon{
    width: 32px;
    height: 32px;
}

.friends-skeleton{
    display: inline;
    margin: 0 auto;
    margin-bottom: 20px;
    text-align: center;
}

.handle-name{
    padding-bottom: 20px;
    font-size: 24px;
    @include kozuka-gothic-font;
}
.bio-text{
    text-align: left;
    font-size: 16px;
    white-space: pre-line;
    @include kozuka-gothic-font;
}
.friends-count{
    font-size: 20px;
    @include kozuka-gothic-font;
    margin-left: 15px;
    margin-top: 15px;
}
.mypage-friends-container {
    padding: 0!important;
    padding-left: 1.25rem!important;
    padding-right: 1.25rem!important;
    padding-bottom: 1.25rem!important;
}

.icon-name{
    text-decoration: none;
    color:black;
    padding-top: 10px;
    @include kozuka-gothic-font;
    font-size: 16px;
}

.friend-card{
    margin-bottom: 28px;
}

@media screen and (max-width: 1199px) {
    .friend-image{
        background-color: #98A6B5;
        object-fit: cover;
        width: 200px;
        height: 200px;
        border-radius: 10px;
    }

    .friend-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 30%;
        text-align: center;
        margin: 0px 10px;
    }

    .friendpage-friend-menu-pos {
        top: 534px;
        left: 144px;
    }

    .friendpage-profile-menu-pos {
        top: 534px;
        right: 92px;
    }
}

@media screen and (max-width: 992px) {
    .friend-image{
        background-color: #98A6B5;
        object-fit: cover;
        width: 150px;
        height: 150px;
        border-radius: 10px;
    }

    .friend-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 30%;
        text-align: center;
        margin: 0px 9px;
    }

    .friendpage-friend-menu-pos {
        top: 534px;
        left: 54px;
    }

    .friendpage-profile-menu-pos {
        top: 534px;
        right: 4px;
    }
}

@media screen and (max-width: 638px) {
    .friend-card{
        margin-bottom: 0px;
    }
    .mypage-profile-container-mobile{
        padding: 0!important;
    }
    .friend-image{
        object-fit: cover;
        width: 150px;
        height: 150px;
        border-radius: 10px;
    }
    .friend-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 10%;
        text-align: center;
        margin: 0px 58px;
    }
    .friends-card-container{
        display: flex;
        flex-direction: row;
        padding: 0px!important;
        padding-top: 10px!important;
    }
    .friend-handlename{
        font-size: 14px;
        padding-top: 10px;
        width: 90px;
    }
    .profile-cover-image{
        width: 100%;
        object-fit: cover;
        height: 70%;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
    }
    .mypage-profile-photo-container{
        top: 155px;
    }
    .mypage-bio-container{
        padding-top: 0px!important;
    }
    .horizontal-border{
        border-top: .5px solid #DCDCDC;
        width: 90%!important;
        margin: auto;
    }
    .profile-category, .profile-category-detail{
        font-size: 14px;
    }
    .mypage-profile-menu{
        top: 250px;
        border-radius: 3px;
    }
    .mypage-profile-menu-items{
        top: 250px;
        right: 20px;
        width: 160px;
        font-size: 12px;
    }
    .menu-item p {
        font-size: 12px;
        margin-top: 3px
    }
    .preview-icon{
        width: 36px;
        height: 36px;
    }
    .icons-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90px;
        text-align: center;
        margin: 0 5px;
    }

    .icon-name{
        font-size: 13px;
    }
    .bio-text{
        font-size: 14px;
    }
    .category-container{
        font-size: 14px;
    }
    .friends-count{
        font-size: 18px;
        margin-left: 20px;
    }

    .friendpage-friend-menu-pos {
        top: 452px;
        left: 95px;
    }

    .friendpage-profile-menu-pos {
        top: 452px;
        right: 108px;
    }
}


@media screen and (max-width: 595px) {
    .friend-image{
        background-color: #98A6B5;
        object-fit: cover;
        width: 150px;
        height: 150px;
        border-radius: 10px;
    }

    .friend-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 30%;
        text-align: center;
        margin: 0px 10px;
    }

    .friendpage-friend-menu-pos {
        top: 452px;
        left: unset;
        right: 63%;
    }

    .friendpage-profile-menu-pos {
        top: 452px;
        right: unset;
        left: 57%;
    }
}

@media screen and (max-width: 414px) {
    .friend-image{
        background-color: #98A6B5;
        object-fit: cover;
        width: 105px;
        height: 105px;
        border-radius: 10px;
    }

    .friend-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 21%;
        text-align: center;
        margin: 0px 22px;
    }
}

@media screen and (max-width: 320px) {
    .friend-image{
        object-fit: cover;
        width: 85px;
        height: 85px;
        border-radius: 10px;
    }
    .friend-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 10%;
        text-align: center;
        margin: 0px 33px;
    }
}
