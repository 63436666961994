@import '../../../../styles/scss/global';

.profile-input-last {
    padding-bottom: 20px;
}

.row-label {
    align-self: flex-start;
}

.profile-template-button {
    &-primary{
        background-color: $user_primary !important;
        border: none !important;
    }
}

.profile-birthday-select select {
    margin-right: 8px
}

.profile-text-align-flex-start {
    align-items: flex-start !important;
    flex: 1 0 auto;
}

.profile-text-flex {
    flex: 1 0 auto;
    max-width: 100%;
}

.profile-hobby-select {
    width: 75% !important;
}

.profile-select-birthdate {
    flex-wrap: unset !important;
}

.contact-field {
    border-radius: 14px!important;
    font-size: 16px;
    min-height: 50px!important;
    border-color: #b1bcc7!important;
    border-width: 1px!important;
    border-style: solid;
    resize: none;
}

.submit-btn {
    width: 100%;
    border-radius: 14px!important;
    height: 50px;
    background-color: #0a182c!important;
    border: 0!important;
}

.page-title {
    display: flex;
    background-color: #f5f5f5;
    color: "#0A182C";
    margin-top: 0px;
    margin-bottom: 0px;
    height: 55px;
    padding-left: 24px;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #dcdcdc;
    white-space: nowrap;
}

.radio-label{
    margin-left: -16px;
    margin-right: 20px;
}
.radio-form .label-container:last-child .radio-label{
    margin-right: 0px !important;
}

@media only screen and (min-width: 768px) {
    .profile-input {
        border-bottom: 1px solid #DCDCDC;
        padding-bottom: 20px;
    }
}

@media only screen and (max-width: 768px) {
    .profile-hobby-select {
        width: 100% !important;
    }

    .profile-input {
        margin-bottom: 0 !important
    }
    .page-title{
        height: 52px;
    }
}

@media only screen and (min-width: 638px) {
    .contact-field, .submit-btn {
        border-radius: 8px!important;
    }

    .contact-field {
        border-color: #ccd4da !important
    }

    .submit-btn {
        max-width: 400px;
    }

    .page-title {
        justify-content: flex-start;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
}