.hamburger-container{
    margin-top: 8px !important;
    width: 330px !important;
    overflow-y: scroll;
    max-height: 80vh;
}

.hamburger-container-mobile{
    margin-top: 18px !important;
    width: 330px !important;
    overflow-y: scroll;
    max-height: 80vh;
}