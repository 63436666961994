.form-label{
    margin-bottom: 0px !important;
    font-weight: 500;
}

.form-input{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.form-control{
    border: 1.5px solid #E6E6E6;
}

select.form-control{
    padding: 0px .75rem !important;
}

.user-form-control{
    border: 1.25px solid #B1BCC7;
    font-size: 14px;
}

.form-group{
    margin-bottom: 2rem;
}

.form-check-label{
    cursor: pointer;
    margin-left: 4px;
    margin-right: 10px;
}

.btn-toolbar>*+* {
    margin-left: .5em;
    margin-right: .5em;
}

.table td, .table th{
    padding: .25rem;
}

input[type='radio']{
    margin-top: 4px !important;
}
input[type='radio']:after {
    width: 18px;
    height: 18px;
    border-radius: 18px;
    top: -4px;
    padding: 2px;
    left: -1px;
    position: relative;
    background-color: #fff;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #E6E6E6;
    cursor: pointer;
}

input[type='radio']:checked:after {
    width: 18px;
    height: 18px;
    border-radius: 18px;
    top: -4px;
    left: -1px;
    position: relative;
    background-color: #3B4043;
    padding: 2px;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #E6E6E6;
    cursor: pointer;
}

.col-sm-9{
    display: flex;
}

.dropdown-item{
    color: #0A182C !important;
}

.badge-danger{
    color: rgb(255,255,255) !important; 
}

/**Mobile View*/
@media only screen and (max-width: 638px) {
    .container{
        max-width: 100%;
    }
    .user-form-control{
        border-radius: 12px !important;
    }
  }

