.search-item-container{
    border-radius: 10px;
    border: 1px solid #B1BCC7;
    padding: 16px;

    margin-bottom: 20px;
    background-color: #ffffff;
}
.post-text{
    flex: 1;
    font-size: 14px;
    -webkit-line-clamp:4;
    -webkit-box-orient: vertical;
    color: #0A182C;
    align-self: flex-start;
    text-decoration: none;
}
.search-attachment{
    width: 90px;
    height: 80px;
    object-fit: cover;
    border-radius: 10px;
    margin-left: 8px;
    background: #000;
    border: 0px;
}
.search-attachment-video{
    width: 90px;
    height: 80px;
    border-radius: 10px;
    margin-left: 8px;
    background: #000;
    border: 0px;
}
.search-attachment:hover, .search-attachment-video:hover{
    border: 0px;
}
.search-attachment-overlay{
    width: 90px;
    height: 80px;
    position: absolute;   
}

.search-comment-count{
    display: flex;
    justify-content: flex-end;
    text-decoration: none !important;
    color: #98A6B5; 
    border-top: 1px solid #DCDCDC;
    padding-top: 8px;
    font-size: 14px;
    margin-top: 8px;
}
.search-comment-count:hover{
    color: #98A6B5; 
}
.search-body, .search-body:hover{
    color: #0A182C;
    text-decoration: none !important;
}
.play-icon{
    position: absolute;
    left: 40%;
    transform: translateX(-50%);
    top: 45%;
    transform: translateY(-50%);
    width: 35px;
    height: 35px;
}
.no-result{
    display: flex;
    justify-content: center;
    align-self: center;
    margin: 40px;
}
.user-handleName{
    white-space: nowrap;
    text-overflow: ellipsis;
    width: auto;
    overflow: hidden;
    font-variant: proportional-width;
}
.search-post-body{
    word-break: break-word;
    white-space: pre-wrap !important;
}
.userResult{
    display: flex;
    flex-direction: row;
    align-items: center;
}
/**PC View*/
@media only screen and (min-width: 638px) {
    .search-item-container{
        border: 1px solid #DCDCDC;
    }
    .search-attachment-overlay, .search-attachment, .search-attachment-video{
        width: 140px;
        height: 120px;
    }
    .play-icon{
        left: 36%;
        transform: translateY(-50%);
        width: 50px;
        height: 50px;
    }
}

@media (max-height: 568px) {
    .no-result{
        margin: 35px;
    }
}

@media only screen and (max-width: 638px) {
    .post-text{
        display: block; /* Fallback for non-webkit */
        display: -webkit-box;
        height: 26*1.4*3; /* Fallback for non-webkit */
        flex: 1;
        font-size: 14px;
        -webkit-line-clamp:4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #0A182C;
        max-height: 90px !important;
        align-self: flex-start;
        font-variant: proportional-width;
    }
}