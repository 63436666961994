@import "../../../../styles/scss/global";
@import "../../../../styles/scss/layout";

.mainUserSearchContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.userManagement{
    @include flex-center;

    &-error{
        color: red;
    }

    &-search-card{
        width: 100%;
    }

    &-submit-btn{
        align-self: center;
        width: 250px;
    }

    &-form{
        width: 700px;
        align-self: center;
        margin-left: -3rem;
    }
}


  @media only screen and (max-width: 1240px) {
    .userManagement-form{
        max-width: 90%;
        min-width: 90%;
    }
  }
