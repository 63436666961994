@import '../../../../styles/scss/global';
@import '../../../../styles/scss/layout';

// Common to Chat Screens
.chat-list-container {
  background-color: white;
  display: flex;
  flex: 1;
  flex-shrink: 0;
  align-items: stretch;
  flex-direction: column;
  position: relative;
  padding: 0 0 0 10px;
  border: 1px solid #dcdcdc;
  height: 87vh;
  overflow: hidden;
  border-radius: 5px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.add-image{
  width: 80px;
  height: 80px;
}

.list-container {
  background-color: #ffffff;
  height: 75vh;
  overflow: hidden;
}

.ff-list-container {
  background-color: #ffffff;
  height: 75vh;
}

.ff-message-container{
  flex: 1;
  flex-direction: column;
  background-color: white;
  padding: 0 10px;
  height: 88%;
}

.newchat-container {
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  overflow: hidden;
  border-radius: 5px;
}
.newchat-header{
  height: 55px;
  background-color: #F5F5F5;
  border-bottom: 1px solid #dcdcdc;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.search-input{
  font-weight: 400;
  font-size: 16px !important;
  line-height: normal;
}
.flatlist-container {
  // height: 100%;
  display: flex;
  flex: 1;
}

//Chat Conversation Screen
.send-container {
  background-color: #f5f5f5;
  border: 1px solid #dcdcdc;
  border-top: 0px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.ff-send-container {
  background-color: #f5f5f5;
  border-bottom: 1px solid #dcdcdc;
  border-top: none;
}
.message-container, .image-upload-container {
  flex: 1;
  flex-direction: column;
  background-color: white;
  padding: 0 10px;
  height: 90%;
  position: relative;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.image-upload-container {
  padding: 0 0 0 10px;
}
.message-container{
  padding: 0 0 0 10px;
}
.chat-conversation {
  border: 1px solid #DCDCDC;
  border-bottom: 0px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.chat-image{
  image-orientation: from-image;
}

.chat-image-container {
  padding: 5px 0px;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 10px;
  max-width: 250px;
}

.chat-image-friend-pc{
  max-width: 300px;
  border-radius: 5px;
}

.chat-image-user-pc{
  max-width: 320px;
  min-height: 200px;
  cursor: pointer;
  border-radius: 5px;
}

.chat-image-pc-container {
  padding: 5px;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 10px;
  max-width: 350px;
}

.chat-textarea-container{
  margin: 13px 0;
  width: 100%;
  height: auto;
  text-align: left;
}

.chat-textarea{
  @include chat-rounded-input;
  width: 100%;
  font-size: 14px !important;
  resize: none;
  cursor: text;
  margin-bottom: 5px;
}
.chat-textarea:focus{
  box-shadow: none!important;
}


input[type="file"] {
  display: none;
}

.chat-image-upload-container{
  height: 100px;
  width: 98%;
  background-color: #ffffff;
  color: #fff;
  text-align: center;
  z-index: 2;
  padding-top: 4px;
  position: relative;
  bottom: 90px;
}
.chat-image-upload-container-mobile{
  height: 100px;
  width: calc(100%-14px);
  background-color: #ffffff;
  color: #fff;
  text-align: center;
  z-index: 1;
  position: relative;
}

.file-upload-container{
  padding: 0px 5px;
  position: relative;
}

.image-preview-container{
  position: relative
}

.image-preview{
  border: 1px solid #B1BCC7;
  display: inline-block;
  width: 80px;
  height: 80px;
  margin: 0 5px;
  border-radius: 10px;
  object-fit: cover;
}
.chat-custom-file-upload {
  display: inline-block;
  width: 80px;
  height: 80px;
  cursor: pointer;
}

.chat-avatar{
  object-fit: cover;
  border-radius:50%;
  margin-top: 5px;
  width: 35px;
  height: 35px;
}

//New Chat
.avatar{
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius:50%;
}
.header-avatar{
  width: 36px;
  height: 36px;
  object-fit: cover;
  border-radius:50%;
}
.new-chat-header-text {
  color: #0A182C;
  padding-top: 15px;
  margin-left: 32px;
  font-size: 16px;
  @include kozuka-gothic-font;
}

//Error messages
.chat-form-error{
  color: red;
  font-size: 12px;
  margin: 0;
  bottom: 0;
  top: 22px;
  text-align: left;
}

//Chat Image Upload
.add-image-container {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 100px;
  overflow-x: auto;
  overflow-y: hidden;
}

::-webkit-scrollbar-thumb
{
  border-radius: 5px;
  background-color: #E2E2E2;
  overflow-x: auto;
}

.add-image-container::-webkit-scrollbar {
  background: #fff;
  width: 12px;
  height: 7px; 
}

//Chat Conversation Container
.chat-items-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  z-index: -999;
  padding-top: 0px;
  padding-bottom: 40px;
  padding-right: 8px;
}

.chat-items-image {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
  padding-bottom: 130px;
}

.chat-items-image2 {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
  padding-bottom: 100px;
}

/* Landscape */
@media only screen and (min-device-width: 480px) and (max-device-width: 768px) and (orientation: landscape) {
  .chat-items-container{
    padding-right: 0px;
    max-height: 70vh;
    padding-bottom: 70px;
  }
}

@media (max-width: 768px) {
  .chat-items-image::-webkit-scrollbar, .chat-items-image2::-webkit-scrollbar,  .chat-textarea::-webkit-scrollbar {
    background: #fff;
    width: 0px!important;
  }
  .header-text, .new-chat-header-text {
    color: #0A182C;
    text-align: center;
    margin-left: 0;
  }
  .chat-image-upload-container, .chat-image-upload-container-mobile {
    height: 90px;
  }

  .add-image-container{
    height: 90px;
  }
  .chat-items-container{
    padding-right: 0px;
  }
  .chat-items-container::-webkit-scrollbar {
    width: 0px;
  }
}

@media (max-width: 638px) {
  .chat-items-image,.chat-items-image2 {
    padding-bottom: 150px;
  }
  .newchat-container {
    border: none!important;
    border-radius: 0px;
  }
  .chat-form-error{
    margin-left: 0px
  }
  .header-text {
    font-size: 14px;
  }
  .search-input{
    @include kozuka-gothic-font;
    font-size: 14px !important;
  }
  .chat-conversation{
    border: none!important;
  }
  .send-container{
    border: none;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .chat-conversation{
    border-radius: 0px;
  }
  .chat-list-container {
    height: auto;
    padding-bottom: 2px;
    padding: 0 10px 2px 10px;
    border: none!important;
    border-radius: 0px;
  }

  .chat-items-container {
    padding-bottom: 80px;
  }

  .send-container {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: white;
    background-color: #f5f5f5;
    text-align: center;
    z-index: 3
  }
  .message-container {
    padding: 0 10px;
    height: 92%;
    position: relative;
  }
  .ff-message-container {
    padding: 0 10px;
    height: 88%;
    position: relative;
  }
  .ff-send-container {
    border: none
  }
  .chat-image-upload-container{
    width: 100%;
    position: relative;
    bottom: 110px
  }
  
  .chat-image-upload-container-mobile{
    width: 100%;
    position: relative;
  }
  
  .chat-image-friend-mobile{
    max-width: 180px !important;
    border-radius: 10px;
  }
  .chat-image-user-mobile{
    max-width: 180px;
    min-height: 100px;
    cursor: pointer;
    border-radius: 10px;
  }
  .chat-custom-file-upload {
    display: inline-block;
    width: 50px;
    height: 50px;
    cursor: pointer;
  }
  .add-image{
    width: 70px;
    height: 70px;
  }
  .image-preview{
    border: 1px solid #B1BCC7;
    display: inline-block;
    width: 70px;
    height: 70px;
    margin: 0 5px;
    border-radius: 10px;
  }
  .newchat-container {
    background-color: #ffffff;
    height: auto;
  }
  .chat-form-error{
    font-size: 11px;
  }
  .list-container {
    height: 81vh;
  }
  .ff-list-container {
    height: 90vh;
  }
}
@media (max-width: 320px) {
  .image-upload-container {
    height: 95%;
    padding-bottom: 80px!important;
  }
  .message-container {
    height: 95%;
    padding-bottom: 70px;
  }
  .list-container {
    height: 80vh;
  }
  .ff-message-container{
    height: 88%
  }
  .chat-items-container {
    padding-bottom: 40px;
  }
}
@media (max-width: 638px) {
  .newchat-header{
    height: 52px;
  }  
}

@media (max-height: 736px) and (max-width:638px){
  .message-container {
    padding: 0 10px;
    padding-bottom: 45px!important;
  }
  .image-upload-container {
    padding: 0 10px;
    padding-bottom: 50px;
  }
  .ff-message-container {
    padding: 0 10px;
    padding-bottom: 0px!important;
  }
}

@media (max-height: 667px) and (max-width:638px) {
  .message-container {
    padding: 0 10px;  
    padding-bottom: 60px!important;
  }
  .ff-message-container {
    padding: 0 10px;
    padding-bottom: 0px!important;
  }
}

@media (max-height: 568px) and (max-width:638px){
  .message-container {
    padding: 0 10px;
    padding-bottom: 80px!important;
  }
  .ff-message-container {
    padding: 0 10px;
    padding-bottom: 0px!important;
  }
  .chat-items-container {
    padding-bottom: 40px;
  }
}

@media (max-height: 812px) and (max-width:375px){
  .message-container {
    padding: 0 10px;
    padding-bottom: 10px;
  }
  .ff-message-container {
    padding: 0 10px;
    padding-bottom: 0px!important;
  }
}

@media (max-height: 812px){
  .list-container{
    height: 80vh;
  }
  .ff-list-container {
    height: 90vh;
  }
}

@media (max-height: 756px){
  .chat-image-upload-container{
    bottom: 100px;
  }
}

@media (max-height: 638px){
  .list-container{
    height: 75vh;
  }
  .ff-list-container {
    height: 85vh;
  }
  .chat-image-upload-container{
    bottom: 110px;
  }
}
@media (max-height: 568px){
  .chat-image-upload-container{
    bottom: 116px;
  }
}
@media (max-height: 424px){
  .chat-image-upload-container{
    bottom: 124px;
  }
}