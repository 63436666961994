@import '../../../../styles/scss/global';
@import '../../../../styles/scss/layout';

//Hide Vimeo Embed Button
.player .vp-sidedock{
    display: none !important;
}

//Main Style
.post-body-button{
    font-size: 14px !important;
    text-decoration: none !important;
    color: $admin_grey !important;
    padding: 0px 4px 0px 0px !important;
}

.post-title{
    margin-top: 8px;
    margin-bottom: 8px;
    font-weight: bold;
}

.post-item{
    &-container{
        font-size: 14px;
        padding: 12px 16px;
    }
    &-body{
        color: $user_primary;
    }
    &-body:hover{
        color: $user_primary;
    }
    &-video-container{
        padding: 56.25% 0 0 0; 
        position: relative;
    }
    &-video-content{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    &-placeholder-video{
        width: 100%;
    }
    &-image-grid{
        padding: 0 !important;
        width: 100% !important;
    }
}

//Facebook Image Resize
.border, .cover{
    border-radius: 0 !important;
    border: 0px solid #fff !important;
}
.height-one{
    padding-top: 65%;
}

.height-two{
    width: 50% !important;
}

.height-three {
    width: 33.3333% !important;
}
