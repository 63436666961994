@import "../../../../styles/scss/global";
@import "../../../../styles/scss/layout";

.notificationManagement{
    @include flex-center;

    &-error{
        color: red;
    }

    &-button{
        @include padding-vertical(8px);
        width: 100px;
        background-color: $admin_primary !important;
        border: 0 !important;
        font-weight: bold !important;
    }

    &-modal{
        min-width: 750px;
        min-height: 220px;
        height: auto;
        padding-bottom: 32px;
        padding-left: 24px;
        padding-right: 24px;
        margin-bottom: 5%;
        position: absolute;
        top: 5%;
        background-color: white;
        border-radius: 10px;
        max-width: 900px !important;
    }

    &-modal-overlay{
        @include modal-overlay;
        overflow-y: scroll;
        display: flex;
        justify-content: center;
        z-index: 999 !important;
    }

    &btn-container{
        @include modal-pc-button-container;
    }

    @media (max-width: 1024px) {
        &-modal {
          @include modal-tablet-container;
        }
        &-btn-container {
          @include modal-mobile-button-container;
        }
      }
    @media (max-width: 638px) {
        &-modal {
            @include modal-mobile-container;
        }
    }
}



