@import '../../../../styles/scss/layout';


.feed-main{
    padding-bottom: 30px;
}

.preview{
    padding-bottom: 30px;
    margin-top: 10px;
}

.feed-card-header{
    padding: 10px 20px;
    background-color: #F4F4F4;
    font-size: 16px;
    font-weight: 500;
}

.feed-card-container{
    @include flex-row-center;
    padding: 24px 20px !important;
    border: none !important; 
}

.feed-card-create{
    flex: 1;
}
.feed-container-item{
    margin: 20px 0px;
}

.feed-main .feed-container-item{
     margin: 10px 0px;
}

.dropdown-menu{
    z-index: 3!important
}

.detail-hidden {
    display: none;
}

@media screen and (max-width: 638px) {
    .feed-main .feed-container-item{
        margin: 0px 0px 4px 0px;
    }
    .feed-main{
        padding-bottom: 50px;
    }
    .feed-container-item{
        margin: 0px 0px;
    }
    .svg-content-loader{
        height: 170px
    }
    .preview{
        padding-bottom: 30px;
        margin-top: 0px;
    }
}
